'use client';

import React, { useState } from 'react';
import styles from './index.module.scss';
import { FiPlus, FiX } from 'react-icons/fi'; // Importamos los iconos

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: '¿Qué servicios ofrece Kweb?',
    answer:
      'En Kweb, ofrecemos soluciones digitales completas: diseño y desarrollo web, plataformas personalizadas, marketing digital, SEO, gestión de redes sociales, entre otros servicios que impulsan tu presencia online y maximizan tus resultados.',
  },
  {
    question: '¿Cuánto tiempo se tarda en realizar un proyecto?',
    answer:
      'Los plazos varían según la complejidad del proyecto. Sin embargo, generalmente entregamos resultados de calidad en un plazo de 2 a 3 semanas, asegurándonos de optimizar cada fase del desarrollo.',
  },
  {
    question: '¿Ofrecen mantenimiento para sitios web?',
    answer:
      'Sí, contamos con planes de mantenimiento que garantizan que tu sitio esté siempre actualizado, seguro y funcionando de manera óptima, asegurando su rendimiento a largo plazo.',
  },
  {
    question: '¿Puedo obtener un presupuesto personalizado?',
    answer:
      '¡Claro! Nos especializamos en crear soluciones a medida. Contacta con nosotros para que podamos conocer tus necesidades y ofrecerte un presupuesto personalizado adaptado a tu proyecto.',
  },
  {
    question: '¿Cómo puedo empezar un proyecto con ustedes?',
    answer:
      'Comenzar es sencillo. Solo necesitas contactarnos, contarnos sobre tu proyecto y nosotros te guiaremos en cada paso. Juntos definiremos la mejor estrategia y te presentaremos una propuesta adaptada a tus objetivos.',
  },
];


const FAQs: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.faqSection}>
      {faqData.map((item, index) => (
        <div key={index} className={`${styles.faqItem} ${activeIndex === index ? styles.active : ''}`}>
          <button className={styles.faqQuestion} onClick={() => toggleAnswer(index)}>
  <span className={styles.icon}>
    {activeIndex === index ? <FiX /> : <FiPlus />}
  </span>
  {item.question}
</button>
          <div className={`${styles.faqAnswer} ${activeIndex === index ? styles.faqAnswerActive : ''}`}>
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQs;
