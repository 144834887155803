'use client';

import { Avatar } from "@heroui/react";
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import styles from './index.module.scss';

export default function InfinitScrollCompanies({ companies, baseVelocity = 50 }: { companies: { icon: string }[], baseVelocity?: number }) {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const animationDuration = 12;

    const items = companies.map((slide, index) => ({
        ...slide,
        number: index + 1,
        icon: <Avatar src={slide.icon} radius="none" className="bg-transparent w-full h-auto max-w-xs max-h-xs object-contain grayscale icon-hover-effect" />
    }));
    const duplicatedSlides = [...items, ...items, ...items, ...items];

    return (
        <div className="relative h-full overflow-hidden pb-5 bg-white mx-auto " style={{ width: isMobile ? '100%' : '100%' }} data-aos="fade-up" data-aos-delay="100" data-aos-duration="600" aria-labelledby="carousel-heading">
            <header className="sr-only">
                <h2 id="carousel-heading">Carrusel de Iconos</h2>
            </header>
            <div className="absolute inset-0 z-20 sm:before:absolute sm:before:left-0 sm:before:top-0 sm:before:w-1/4 sm:before:h-full sm:before:bg-gradient-to-r sm:before:from-white sm:before:to-transparent sm:before:filter sm:before:blur-3 sm:after:absolute sm:after:right-0 sm:after:top-0 sm:after:w-1/4 sm:after:h-full sm:after:bg-gradient-to-l sm:after:from-white sm:after:to-transparent sm:after:filter sm:after:blur-3"></div>
            <motion.div
                className="flex"
                animate={{
                    x: ['0%', isMobile ? '-1000%' : '-100%'],
                    transition: {
                        ease: 'linear',
                        duration: isMobile ? 60 : 15,
                        repeat: Infinity,
                    }
                }}
            >
                {duplicatedSlides.map((slide, index) => (
                    <div key={index} className="flex-shrink-0" style={{ width: `200px` }}>
                        <div className="flex items-center justify-center h-full  " aria-hidden="true">
                            {slide.icon}
                        </div>
                    </div>
                ))}
            </motion.div>
        </div>
    );
}
